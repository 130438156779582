@tailwind components;

@layer components {
  .input-container {
    @apply relative;

    .input-element {
      @apply absolute top-2/4 flex h-[40px] w-9 origin-top-left -translate-y-2/4 items-center justify-center;

      &.left {
        @apply left-[1px];
      }

      &.right {
        @apply right-[1px];

        .btn {
          @apply right-2;

          .icon {
            @apply h-4 w-4;
          }
        }
      }
    }

    .input {
      @apply w-full  rounded-[4px] border border-grey-500 bg-white py-2 px-3 text-body-regular-16 placeholder-grey-400;

      &.with-element-left {
        @apply pl-9;
      }

      &.with-element-right {
        @apply pr-9;
      }

      &.disabled {
        @apply border-grey-300 opacity-50;
      }

      &.error {
        @apply !ring-error;
      }

      &.success {
        @apply !ring-success;
      }

      &:focus-visible {
        @apply border-primary-500 outline outline-[4px]  outline-primary-100;
      }
    }
  }
}
