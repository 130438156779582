@tailwind components;

@layer components {
  .avatar {
    @apply overflow-hidden rounded-full bg-success;

    &.sm {
      @apply h-6 w-6;
    }

    &.md {
      @apply size-8;
    }

    &.lg {
      @apply h-10 w-10;
    }
  }
}
