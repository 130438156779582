@tailwind components;

@layer components {
  .image-card {
    @apply relative cursor-pointer overflow-hidden rounded-xl;

    &:focus-visible {
      @apply outline-2 outline-offset-4 outline-[#EEEEEE];
    }

    &.sm {
      @apply h-16 w-16;
    }

    &.md {
      @apply h-40 w-40;
    }

    &.lg {
      @apply h-64 w-64;
    }
  }
}
