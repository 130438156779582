@tailwind components;

@layer components {
  .image {
    @apply h-auto w-auto;

    &.cover {
      @apply object-cover;
    }

    &.contain {
      @apply object-contain;
    }
  }
}
