/**
 * Class to override react-quill css
 */

.quill {
  .ql-toolbar {
    border-radius: 4px 4px 0px 0px !important;
    border: 1px solid #4f4f4f !important;
    border-bottom: 0px !important;
  }

  .ql-container {
    border-radius: 0px 0px 4px 4px !important;
    border: 1px solid #4f4f4f !important;
    height: 150px;
  }
}
