@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply min-h-full w-full;
}

#root {
  @apply scroll-smooth;
}

.simplebar-content {
  @apply h-full;

  .simplebar-content-wrapper {
    @apply outline-none;
  }
}

@layer components {
  .bg-gradient {
    @apply bg-gradient-to-r from-secondary-500 to-primary-500;
  }

  .text-gradient {
    @apply bg-gradient-to-r from-secondary-500 to-primary-500 bg-clip-text text-[transparent];
  }
}

@layer utilities {
  .bullet-point-before {
    @apply before:absolute before:top-1/2 before:block before:h-2 before:w-2 before:-translate-x-8 before:-translate-y-1/2 before:rounded-full;
  }
}

.swiper {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    /* @apply text-3xl text-secondary-500; */
    /* rimosso text-3xl e sostituito con text-xl */
    @apply text-xl text-secondary-500;
  }
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background: #f7f7f7;
}

::-webkit-scrollbar-thumb {
  background-color: #cfd0d5;
  border-left: 4px solid #f7f7f7;
  border-right: 4px solid #f7f7f7;

  border-top: 4px solid #f7f7f7;
  border-bottom: 4px solid #f7f7f7;
  border-radius: 25px;
}

.react-flow__attribution a {
  display: none !important;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* List */
ul {
  list-style-type: circle;
  margin-left: 20px;
}

ol {
  list-style-type: decimal;
  margin-left: 20px;
}
