@tailwind components;

@layer components {
  .textarea-container {
    @apply relative flex flex-col space-y-2;

    &.full-width {
      @apply w-full;
    }

    &.full-height {
      @apply h-full;
    }

    .length-container {
      @apply flex w-full justify-end;
    }

    .textarea {
      @apply size-full rounded-[0.375rem]  bg-white px-3 py-2 text-body-regular-16 text-grey-600  placeholder:text-grey-300;

      &.disabled {
        @apply bg-[#E2E2E2] ring-0;
      }

      &.error {
        @apply !ring-error;
      }

      &.success {
        @apply !ring-success;
      }
      &:focus-visible {
        @apply !border-primary-500 outline outline-[4px] outline-[#0042D04D];
      }
    }
  }
}
