@tailwind components;

@layer components {
  .checkbox-container {
    @apply relative h-fit w-fit;

    .icon {
      @apply pointer-events-none absolute top-2/4 left-2/4 mt-0.5 hidden  -translate-x-2/4 -translate-y-2/4 fill-grey-50 stroke-white;
    }

    .checkbox:checked + .icon {
      @apply block stroke-primary-500;
    }

    .checkbox {
      @apply h-5 w-5 shrink-0 cursor-pointer appearance-none rounded-[2px] border-[1px] border-grey-200 bg-white align-middle text-secondary-500;
    }

    &.error {
      .checkbox {
        @apply ring-error;
      }
    }

    &.disabled {
      .checkbox {
        @apply pointer-events-none border-grey-50 bg-grey-300;
      }
      .checkbox:checked + .icon {
        @apply block stroke-grey-200;
      }
    }
  }
}
