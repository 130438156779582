.flip {
  /* width: 375px; */
  height: 375px;
  margin: auto;
  perspective: 600px;
}
.flip-content {
  width: 100%;
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.3s;
}

.flip-front,
.flip-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flip-back {
  transform: rotateY(180deg);
}
