@tailwind components;

@layer components {
  .tabs-container {
    @apply w-full;

    .tabs-list {
      @apply flex justify-between;

      .tab-item {
        @apply relative size-full flex-auto;
        @apply after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-full after:bg-success/50 first:after:rounded-bl-full first:after:rounded-tl-full last:after:rounded-br-full last:after:rounded-tr-full;

        .tab {
          @apply mb-4 mt-2 flex justify-center text-success/50;

          &:focus-visible {
            @apply outline-1 outline-offset-4 outline-success/50;
          }
        }

        &.active {
          @apply after:rounded-full after:bg-success;

          .tab {
            @apply text-success;
          }
        }
      }
    }

    .tab-content-container {
      @apply py-4;
    }
  }
}
