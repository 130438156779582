@tailwind components;

@layer components {
  .chip {
    @apply flex h-7 min-w-[75px] items-center justify-between space-x-2 rounded-[10px] border border-primary-500 bg-white px-2;

    &.with-btn {
      @apply pr-0.5;
    }
  }
}
