@tailwind components;

@layer components {
  .btn {
    @apply flex w-auto max-w-[90vw] items-center justify-center gap-4 rounded-[10px] font-sans text-body-regular-18;

    &.primary {
      @apply border-[1px] border-primary-500 bg-primary-500 text-white hover:border-primary-400 hover:bg-primary-400;

      &.disabled {
        @apply border-grey-200 bg-grey-200 hover:bg-grey-200;
      }
    }

    &.secondary {
      @apply border-[1px] border-primary-500 bg-white text-primary-500 hover:bg-primary-100;

      &.disabled {
        @apply border-grey-200 text-grey-200 hover:bg-white;
      }
    }

    &.warning {
      @apply border-[1px] border-error bg-error text-white hover:border-black hover:text-black;

      &.disabled {
        @apply border-grey-200 bg-grey-200 hover:bg-grey-200;
      }
    }

    &.ghost {
      @apply border-none bg-grey-50 hover:bg-primary-100;
    }

    &.tertiary {
      @apply border-[1px] border-grey-300 bg-grey-200  hover:bg-grey-300;
    }

    /* .gradient {
        @apply text-white border-none bg-gradient pressed:scale-90;
      } */

    &.invisible {
      @apply !h-min !w-min border-none bg-grey-50 hover:bg-grey-200;
    }
  }
}
